import './App.css';
import TopTabs from "./components/TabComponent/top-tabs";

function App() {
  return (
    <div className="hsg-container">
      <div className='hsg-body'>
        <h1>Harvivek Singh Gahunia</h1>
        <hr className="top-bar" />
        <h2>artist | writer</h2>
        <TopTabs />
      </div>
      <div className='hsg-footer'>
          <div className='social-links'>
              <a href="https://bsky.app/profile/harvivekgahunia.bsky.social">
                <img className="bluesky" src="../img/bluesky-logo.svg" alt="Bluesky link" title="Bluesky link" />
              </a>
              <a href="https://www.linkedin.com/in/vivekgahunia/">
                <img className="linked-in" src="../img/linkedIn-icon.svg" alt="LinkedIn link" title="LinkedIn link" />
              </a>
              <a href="https://www.goodreads.com/user/show/5917210-v-gahunia">
                <img className="goodreads" src="../img/goodreads.png" alt="Goodreads link" title="Goodreads link" />
              </a>
          </div>
      </div>
    </div>
  );
}

export default App;
