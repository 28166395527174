import React, { useState } from "react";

const ExtinctionLove = () => {

  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div className="extinction-love bookPanel">
      <div className="textContainer">
        <h4>Extinction Love</h4>
        <h3>STATUS</h3>
        <p>Complete. Looking to release as soon as possible, one way or another</p>
        <h3>ELEVATOR PITCH</h3>
        <p>When the false core of Jay Singh’s and Nazma Sidhu’s quiet marriage is explosively revealed, in sorting through the aftermath of personal devastation they find reason to cherish each other all the more.</p>
        <h3>INFLUENCES / COMPARISONS</h3>
        <div class="comps">
          <p><i>The Dead</i>, James Joyce</p> 
          <p><i>On Chesil Beach</i>, Ian McEwan</p> 
          <p><i>Life</i>, Keith Richards</p>
          <p><i>Dom Casmurro</i>, Machado de Assis</p>
          <p><i>Second Place</i>, Rachel Cusk</p>
          <p className="full-width"><i>Erotic Stories for Punjabi Widows</i>, Balli Kaur Jaswal</p>
        </div>
        <h3>AUTHOR'S NOTE</h3>
        <p>A voice-y novel. Homage to the community I grew up in. No punches pulled. Deep-dive into a fascinating milieu, a Golden Age of Sikh-America. Humor and heart in abundance.</p>
        <div className={`read-more-text-el ${isExpanded ? "show" : ""}`}>
            <hr />
            <p>Jay’s an easy-going guy. But he’s uncomfortable at massive events like a Punjabi wedding. And here’s an especially big one between two blue bloods of mid-Atlantic Sikh communities. All that success, all that bling, he’s never been able to keep up. These are Nazma’s people anyways. She grew up with the father of the groom, Jeet. They have a romantic history, but that was long ago. And Jay’s not a jealous type. But he has other reasons to feel concerned about Nazma. Jeet’s wife, Rupinder, means to exclude her from wedding traditions where familial relations are assumed, which is a problem because Nazma’s love is oblivious and she sees herself as family.</p>
            <p>Meanwhile, Jay meets Radhika. They flirt a little. All harmless until it isn’t. As bhangra remixes blare and the wedding’s rumpus comes to its highest pitch, it’s not his duplicity that’s revealed but Nazma’s. Yet in reckoning with one another’s betrayals, what colors their last months together isn’t anger or resentment but a tender reliving of their best of times.</p>
            <p>Written in an easy, raconteur style, Extinction Love is a humorous, insider account of a couple’s compromised place in a newly formed American community. Touching on caste, colorism, sexual identity, hyphenated experiences and Sikh orthodoxy, what’s revealed in Jay’s retelling of this seminal weekend are not only buried truths but the complexity of Sikh-American life.</p>
            <hr />
        </div>
        <button onClick={handleClick}>
          {isExpanded ? "--READ LESS--" : "--READ MORE--"}
        </button>
      </div>
      <div className="imageContainer">
        <img src="../img/extinction-love.png" alt="Extinction Love" />
      </div>
    </div>
  );
};
export default ExtinctionLove;