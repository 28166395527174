import React, { useState } from "react";

const FiregateFour = () => {

  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div className="firegate-four bookPanel">
      <div className="textContainer">
        <h4>Firegate Four</h4>
        <h3>STATUS</h3>
        <p>Soon... months...</p>
        <h3>ELEVATOR PITCH</h3>
        <p>As otherworldly abductions and murders mount in Philadelphia while the world nears the brink of World War III, the fates of three teenagers converge on their discovery of another dying world where they not only uncover the mystery of those crimes but who they are in this world and the other.</p>
        <h3>INFLUENCES / COMPARISONS</h3>
        <div class="comps">
          <p><i>Fullmetal Alchemist</i>, Hiromu Arakawa</p> 
          <p><i>The Water Dancer</i>, Ta-Nehisi Coates</p> 
          <p><i>His Dark Materials</i>, Phillip Pullman</p>
          <p className="full-width"><i>The Boy and the Heron</i>(film), dir. Hayao Miyazaki</p>
        </div>
        <h3>AUTHOR'S NOTE</h3>
        <p>Hot mess but a wild ride. Fatalistic but cheerful. Ridiculously difficult to write. Took fifteen years. Long story there, still processing. It all comes together though. When society breaks along cultural/political/racial lines to whom does the American experience belong? For whom its culture? Its legacies? First book of three.</p>
        <div className={`read-more-text-ff ${isExpanded ? "show" : ""}`}>
          <hr />
          <p>The right-wing National party is in power. The American fabric is changing, people are changing. Militias rule the streets. The Midnight Wars buffet the nation. The Drone Wars threaten to spur World War III. In Philadelphia, people are reported missing. But it's when gruesome murders mount that an alternate world bleeds into this one.</p>
          <p>Almas Ananda Virk, 14, witnesses one such murder. Already she’s a paragon of DSM-IV symptomatology and, being Sikh-American, basically a presumed Muslim until proven innocent. She doesn't need any more crazy or undesirability than she's already got. But now her imaginary brother of yore has returned. She sees ghostly figures others don’t see. Then her mother goes missing.</p>
          <p>Suleiman Harding, 15, is a runaway. Budget cuts have caused the closure of his foster home. His best friend, Natalie, left him with a codex before running away herself. The codex provides an account of an unlikely courtship between a free black woman and Tep Monnik, a man from an alternate world, during Philadelphia's Yellow Fever Epidemic of 1793. As Suleiman draws clues from the book for how he can reach that alternate world, he realizes that Tep Monnik’s story not only unveils his past but also hints why he may be the next tally in Philadelphia's murder count.</p>
          <p>When Esti Ybarra, 16, discovers the dead body of one of Philadelphia's missing persons in her basement, she believes her father is responsible. Esti immigrated with her father to the US from Spain two years ago. He's voiced his support for the National Party but is otherwise a secretive man. As Esti investigates what these secrets might be, she’s shocked to learn how much they pertain to her, too.</p>
          <p>As Almas searches for her mother, Suleiman seeks an understanding of his past and Esti looks for answers to her mysterious father, their paths intertwine and lead them to an alternate world that will unveil who they are and lure them to deeper mystery.</p>
          <hr />
        </div>
        <button onClick={handleClick}>
          {isExpanded ? "--READ LESS--" : "--READ MORE--"}
        </button>
      </div>
      <div className="imageContainer">
        <img src="../img/FiregateFour.png" alt="Firegate Four Cover Art" />
      </div>
    </div>
  );
};
export default FiregateFour;