import React from "react";

const Bio = () => {
  return (
    <div className="bio">
      <img src="../img/bio-pic.png"/>
      <div>
        <p className="opening-line">Software developer by day. Otherwise I write when I can.<br />Been at it a long time.</p>
        <p>Started when I was nineteen. Wrote a genuinely bad novel but managed to sign with an agent. Fortunately, it never saw the light of day. Was a science guy at the time. Neuroscience Bachelors, Pathophysiology Masters. Still I was persistent and wrote some other genuinely bad things. I had no idea how to self-educate really.</p>
        <p>Took a u-turn careerwise. Went to film school. Landed in corporate video for twenty years, managing, producing, developing. Past decade I've evolved to a software/web developer.</p>
        <p>Never stopped writing. Learning, failing, improving too. I don't complain, seldom explain and never give up. So here I am at long last with work to show for it. In it for the long haul and curious to see how it goes.</p>
      </div>
    </div>
  );
};
export default Bio;